@import "styles/theme.scss";
.layout {
  position: relative;
  margin: 0 auto;
  max-width: $laptop;
  width: $laptop;
  max-height: $tablet;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  overflow: hidden;

  /* stylelint-disable */
  /* ----------- iPad Pro 10.5" ----------- */
  @media only screen and (min-width: 810px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: unset;
    max-height: unset;
    width: 100%;
  }

  /* ----------- iPad Air 10.9" ----------- */
  @media only screen and (min-width: 1180px) and (max-width: 1180px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: unset;
    max-height: unset;
    width: 100%;
  }

  /* ----------- iPad Pro 12.9" ----------- */
  @media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    max-width: unset;
    max-height: unset;
    width: 100%;
  }
}

/* stylelint-enable */
