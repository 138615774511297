@import "styles/theme.scss";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
}

:root {
  /* stylelint-disable */
  --_main-gradient: linear-gradient(283.27deg, #f4f6f9 0%, #c6d0df 100%);
  /* stylelint-enable */
  --_primary-text-color: #003251;
  --_primary-accent-color: #00d1ff;
  --_primary-hover-color: #003251;
  --_primary-muted-color: #818ba7;
  --_primary-wrong-color: #ff1f26;
  --primary-success-color: #{$success-color};
  --main-gradient: var(--_main-gradient);
  --primary-text-color: var(--_primary-text-color);
  --primary-accent-color: var(--_primary-accent-color);
  --primary-wrong-color: var(--_primary-wrong-color);
  --primary-hover-color: var(--_primary-hover-color);
  --default-right-color: #{$default-right-color};
  --wrong-color: #{$wrong-color};
  --grey-light-color: #{$grey-light-color};
  --input-radius: #{$input-radius};
  --background-color: #{$light-blue-color};
  --font-size-xs: #{$font-size-xs};
  --shadow-accordion: #{$shadow-main};
  --toastify-font-family: #{$font-family};
  --toastify-toast-width: 470px;
  --toastify-toast-min-height: 54px;
  --toastify-color-success: #e3f9e5;
  --toastify-color-error: #fee;
  --toastify-color-info: #e9fbff;
  --toastify-text-color-info: var(--primary-text-color);
  --toastify-text-color-success: var(--primary-text-color);
  --toastify-text-color-error: var(--primary-text-color);
}

html,
body {
  position: relative;
  line-height: 1.3;
  height: 100%;
  min-height: 100%;
  font-family: $font-family;
  font-weight: $font-weight-light;
  font-style: normal;
  color: var(--primary-text-color);
  -webkit-tap-highlight-color: rgba(0 0 0 / 0%);
}

input {
  user-select: text;
  font: inherit;
}

input,
textarea {
  @include input-focus;
}

input:focus {
  @include input-focus;
}

input:focus-visible {
  @include input-focus;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: none;
  transition: background-color 5000s ease-in-out 0s;
}

b {
  color: inherit;
}

button,
button:active,
button:focus {
  font-family: inherit;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  outline: none;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

/* stylelint-disable */

#__next {
  height: 100%;
}

/* stylelint-enable */
